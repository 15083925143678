import api from "../api";

export default {
    namespaced: true,
    state: {
        loading: false,
    },
    getters: {
        loading(state) {
            return state.loading;
        },
    },
    mutations: {
        SET_LOADING(state, payload) {
            state.loading = payload;
        },
    },
    actions: {
        //context é  como se tem acesso ao store nas actions
    },
};

<template>
  <v-list-item
    @click="clickAcaoMenu(item)"
    class="white--text"
    :class="{
      'lista--ativa': rotaAtiva(item.rota),
    }"
  >
    <v-list-item-icon class="d-flex align-center justify-center mx-0 my-2">
      <!-- <component :is="item.icon" color="#fff" /> -->
      <v-img v-if="item.titulo != 'Minha Escola'" contain max-width="30" max-height="30" :src="item.icon" />
      <v-img v-else cover max-width="40"  max-height="40" :src="item.icon" :aspect-ratio="1" style="border-radius: 50%; transform: scale(1.2); margin-top: 3px;" />
    </v-list-item-icon>

    <v-list-item-title class="ms-5 my-0" :style="{ whiteSpace: 'normal' }">
      {{ item.titulo }}
    </v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "ListItem",
  props: {
    item: { type: Object },
  },
  methods: {
    clickAcaoMenu(menu) {
      if (menu.action) {
        this.$emit("action", menu);
        return;
      }
      if (menu.rota == this.$router.currentRoute.path) {
        return;
      }
      if (menu.rota == "sair") {
        this.$emit("modalSair");
      } else if (menu.rota == "modalAppAgenda") {
        this.$emit("modalAppAgenda");
      } else if (menu.rota) {
        if (menu.rota != this.$route.fullPath) {
          this.$router.push(menu.rota);
        }
      } else {
        this.alertWarning(menu.detalhesModal);
      }
    },

    rotaAtiva(rota) {
      return this.$route.fullPath.indexOf(rota) !== -1;
    },
  },
};
</script>

<style></style>

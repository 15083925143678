export default {
  namespaced: true,
  state: {
    modalSucessoErroWarning: false,
    dadosModalSucessoErro: {}, 
  },
  getters: {
    isModalOpen(state) {
      return state.modalSucessoErroWarning;
    },
    modalData(state) {
      return state.dadosModalSucessoErro;
    },
  },
  mutations: {
    SET_MODAL_OPEN(state, isOpen) {
      state.modalSucessoErroWarning = isOpen;
    },
    SET_MODAL_DATA(state, data) {
      state.dadosModalSucessoErro = data;
    },
    RESET_MODAL(state) {
      state.modalSucessoErroWarning = false;
      state.dadosModalSucessoErro = {};
    },
  },
  actions: {
    openModal(context, data) {
      context.commit("SET_MODAL_OPEN", true);
      context.commit("SET_MODAL_DATA", data);
    },
    closeModal(context) {
      context.commit("SET_MODAL_OPEN", false);
    },
    resetModal(context) {
      context.commit("RESET_MODAL");
    },
  },
};
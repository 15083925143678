let prefixoRota = "/aluno";
const privilegioAluno = [3];
import auth from "../middleware/auth";
import fund2 from "../middleware/fund2";

export default [
  {
    path: prefixoRota + "/home",
    name: "home-aluno",

    component: () => import("../pages/aluno/home/Home.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/videoaulas",
    name: "videoaulas-aluno",
    component: () => import("../pages/aluno/videoaulas/HomeVideoaulas.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/videoaulas/aula/:id_aula",
    name: "videoaulas-aula-aluno",
    component: () => import("../pages/aluno/videoaulas/Videoaulas.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  // nova resposta com a nova modelagem
  {
    path: prefixoRota + "/avaliacoes",
    name: "avaliacoes-aluno-home-modelagem-nova",
    component: () => import("../pages/aluno/novaAvaliacoes/DisciplinasInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: { pagina: "avaliacoes-demo" },
  },
  {
    path: prefixoRota + "/avaliacoes/disciplina/:id_disciplina", 
    name: "avaliacoes-aluno-disciplina",
    component: () => import("../pages/aluno/novaAvaliacoes/HomeAvaliacoes.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: true,
  },
  // {
  //   path: prefixoRota + "/avaliacao-demo/avaliacao/:id_avaliacao/gabarito",
  //   name: "avaliacoes-gabarito-aluno",
  //   component: () => import("../pages/aluno/novaAvaliacoes/GabaritoAvaliacao.vue"),
  //   beforeEnter: auth,
  //   meta: { id_privilegio: privilegioAluno },
  // },
  {
    path: prefixoRota + "/avaliacoes/:id_avaliacao/responder/:modelagem", // este 2
    name: "avaliacoes-responder-aluno",
    component: () => import("../pages/aluno/novaAvaliacoes/ResponderAvaliacao.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path:
      prefixoRota + "/avaliacoes/:id_avaliacao/visualizar-respostas/:gabarito/:modelagem?", // este 3
    name: "avaliacoes-aluno-respostas",
    component: () =>
      import("../pages/aluno/novaAvaliacoes/VisualizarRespostas.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },

  {
    path: prefixoRota + "/avaliacoes-old",
    name: "avaliacoes-aluno-home-modelagem-antiga",
    component: () => import("../pages/aluno/avaliacoes/DisciplinasInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: { pagina: "avaliacoes" },
  },
  {
    path: prefixoRota + "/avaliacoes-old/disciplina/:id_disciplina",
    name: "avaliacoes-aluno-disciplina-modelagem-antiga",
    component: () => import("../pages/aluno/avaliacoes/HomeAvaliacoes.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: true,
  },
  // {
  //   path: prefixoRota + "/avaliacoes/avaliacao/:id_avaliacao/gabarito",
  //   name: "avaliacoes-gabarito-aluno",
  //   component: () => import("../pages/aluno/avaliacoes/GabaritoAvaliacao.vue"),
  //   beforeEnter: auth,
  //   meta: { id_privilegio: privilegioAluno },
  // },
  {
    path: prefixoRota + "/avaliacoes-old/:id_avaliacao/responder",
    name: "avaliacoes-responder-aluno-modelagem-antiga",
    component: () => import("../pages/aluno/avaliacoes/ResponderAvaliacao.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/albuns",
    name: "albuns-aluno",
    component: () => import("../pages/aluno/albuns/AlbunsInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path:
      prefixoRota + "/avaliacoes-old/:id_avaliacao/visualizar-respostas/:gabarito?",
    name: "avaliacoes-aluno-respostas-modelagem-antiga",
    component: () =>
      import("../pages/aluno/avaliacoes/VisualizarRespostas.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/perfil",
    name: "perfil-aluno",
    component: () => import("../pages/aluno/perfil/HomePerfil.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  // nova modelagem
  {
    path: prefixoRota + "/disciplinas",
    name: "disciplinas-inicio",
    component: () => import("../pages/aluno/novaDisciplinas/DisciplinasInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: { pagina: "disciplinas" },
  },
  {
    path: prefixoRota + "/disciplinas-old",
    name: "disciplinas-inicio-modelagem-antiga",
    component: () => import("../pages/aluno/disciplinas/DisciplinasInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: { pagina: "disciplinas" },
  },
  {
    path: prefixoRota + "/disciplinas/:idDisciplina/assistir-video",
    name: "assistir-video-disciplina",
    component: () => import("../pages/aluno/unidade/AssistirVideo.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: true,
  },
  {
    path: prefixoRota + "/comunicados",
    name: "comunicados-aluno",
    component: () => import("../pages/aluno/comunicados/Home.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/mensagens",
    name: "mensagens-aluno",
    component: () => import("../pages/aluno/mensagens/MensagensInicioNova.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/mural",
    name: "mural-aluno",
    component: () => import("../pages/aluno/mural/MuralInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/livros-digitais",
    name: "livros-digitais-aluno",
    component: () => import("../pages/aluno/livrosDigitais/Inicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/agenda",
    name: "agenda-aluno",
    component: () => import("../pages/aluno/agenda/Agenda.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/acompanhamento",
    name: "acompanhamento",
    component: () =>
      import("../pages/aluno/acompanhamento/AcompanhamentoInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },

  //Atividades
  {
    path: prefixoRota + "/atividades/disciplina/:id_disciplina",
    name: "atividades-aluno-disciplina",
    component: () => import("../pages/aluno/atividades/HomeAtividades.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
    props: true,
  },
  {
    path: prefixoRota + "/atividades/:id_atividade/responder",
    name: "atividades-responder-aluno",
    component: () => import("../pages/aluno/atividades/ResponderAtividade.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path:
      prefixoRota + "/atividades/:id_atividade/visualizar-respostas/:gabarito?",
    name: "atividades-aluno-respostas",
    component: () =>
      import("../pages/aluno/atividades/VisualizarRespostas.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioAluno },
  },
  {
    path: prefixoRota + "/issuu",
    name: "LivrosEmbedIssuuAluno",
    ttl: "LivrosEmbedIssuuAluno",
    icon: "mdi-home-outline",
    meta: {
      id_privilegio: privilegioAluno,
    },
    component: () => import("../pages/aluno/LivrosEmbedIssuu.vue"),
    menu: false,
  },

  {
    path: prefixoRota + "/faq",
    name: "faq-aluno",
    component: () => import("../pages/aluno/faqAluno/faqAluno.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioAluno,
    },
  },
  {
    path: "/aluno-fundamental-dois/home",
    name: "AlunoFundamental2",
    beforeEnter: async () => {
      const functionsToRun = [auth(), fund2()];
      await Promise.all(functionsToRun);
    },
    meta: { id_privilegio: privilegioAluno },
  },

  // {
  //   path: prefixoRota + "/atividades/atividade/:id_atividade/gabarito",
  //   name: "atividades-gabarito-aluno",
  //   component: () => import("../pages/aluno/atividades/GabaritoAtividade.vue"),
  //   beforeEnter: auth,
  //   meta: { id_privilegio: privilegioAluno },
  // },
];

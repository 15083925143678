import api from "@/api";

export default async function fund2() {
  const currentURL = window.location.href;
  const path = 'redirect-user'
  const matriculasDemo = JSON.parse(localStorage.getItem("matriculasDemo"));
  let matricula = JSON.parse(localStorage.getItem("usuario"))['matricula'];
  if (matriculasDemo) {
    matricula = matriculasDemo['aluno2'];
  }
  const response = await api.get(`/get-user-redirect-token?matricula=${matricula}`)

  let redirectUrl = "";
  if (currentURL.includes("staging.plataformaevoluir")) {
    redirectUrl = `https://staging.new.plataformaevoluir.com.br/${path}`;
  } else if (currentURL.includes("dev.plataformaevoluir")) {
    redirectUrl = `https://dev.new.plataformaevoluir.com.br/${path}`;
  } else if (currentURL.includes("app.plataformaevoluir")) {
    redirectUrl = `https://new.plataformaevoluir.com.br/${path}`;
  } else if (currentURL.includes("localhost")) {
    redirectUrl = `http://localhost:5173/${path}`;
  }

  // Construa a nova URL com o token do usuário
  let newURL = `${redirectUrl}?user_redirect_token=${response.data.user_redirect_token.token}&matricula=${matricula}`;

  // Obtenha as matrículas Demo
  if (matriculasDemo) {
    // Construa a nova URL com as matrículas Demo
    newURL += `&matriculasDemo=${JSON.stringify(matriculasDemo)}`;
  }

  // Redirecione o usuário para a nova URL
  window.location.replace(newURL);
}

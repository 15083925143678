<template>
	<v-dialog v-model="getIsModalOpen" @click:outside="fechar()" @keydown.esc="fechar()" max-width="980">
		<div class="modal modal-app">
			<div class="modal__content">
				<div class="modal__content-1">
					<img class="modal__content-logo" src="/assets/images/logo-branca.svg" alt="" />
					<img class="modal__content-app" src="/assets/images/app-agenda.png" alt="" />
				</div>

				<div class="modal__content-2">
					<div class="d-flex justify-end">
						<v-btn @click="fechar()" class="ms-3" small fab color="#CE0000">
							<v-icon color="#fff"> mdi-close</v-icon>
						</v-btn>
					</div>
					<div class="pe-0 pe-md-5">
						<h2>App Agenda Evoluir demo</h2>

						<p class="mb-6 mt-2">
							Com o aplicativo Evoluir Agenda, o responsável tem acesso à toda rotina escolar do seu filho. Tenha acesso
							à:
						</p>

						<div class="modal__content-group">
							<div class="modal__content-button">
								<img src="/assets/images/message.svg" alt="" />
							</div>
							<span>Mensagens enviadas por todos os setores da escola;</span>
						</div>

						<div class="modal__content-group">
							<div class="modal__content-button">
								<img src="/assets/images/calendar.svg" alt="" />
							</div>
							<span>Calendário de eventos escolares;</span>
						</div>

						<div class="modal__content-group">
							<div class="modal__content-button">
								<img src="/assets/images/notification.svg" alt="" />
							</div>
							<span>Notificações de novos eventos, mensagens, atividades e frequência do estudante</span>
						</div>

						<footer class="modal__footer">
							<h4>Para ter acesso ao app, siga os seguintes passos:</h4>

							<div class="modal__footer-group">
								<div class="modal__footer-button">1</div>
								<span
									>Abra a App Store (no iOS) ou a Play Store (no Android) em seu dispositivo, procure por ‘’Evoluir
									Agenda’’ e baixe o app.</span
								>
							</div>

							<div class="modal__footer-group">
								<div class="modal__footer-button">2</div>
								<span>Após fazer download e abrir o aplicativo, clique em ‘’fazer login’’ </span>
							</div>

							<div class="modal__footer-group">
								<div class="modal__footer-button">3</div>
								<span>Preencha os campos ‘’matrícula’’ e ‘’senha’’ com os seguintes dados:</span>
							</div>

							<div class="modal__footer-login">
								<div>
									<img src="/assets/images/modal-message.svg" alt="" />
									<strong>Matrícula:</strong>
									<span>{{ matricula }}</span>
								</div>

								<div>
									<img src="/assets/images/modal-password.svg" alt="" />
									<strong>Senha:</strong>
									<span>evoluir123</span>
								</div>
							</div>
						</footer>
					</div>
				</div>
			</div>
		</div>
	</v-dialog>
</template>

<script>
export default {
	name: "ModalInfoApp",
	props: {
		modalAberto: {
			type: Boolean,
		},
	},
	data() {
		return {
      matricula: ''
    };
	},
	methods: {
		fechar() {
			this.$emit("close-modal");
		},
	},
	computed: {
		getIsModalOpen() {
			return this.modalAberto;
		},
	},
  created() {
    const matriculas = JSON.parse(window.localStorage.getItem('matriculasDemo'));
    this.matricula = matriculas['aluno1'];
  },
};
</script>

<style lang="scss" scoped>
@use "@/sass/_utils" as utils;

$blue-color: #364391;
ul {
	list-style: none;
}

.text {
	color: #1c1c1c;
	font-size: 1rem;
	opacity: 0.6;
}

.v-dialog {
	overflow: hidden !important;
}

.modal {
	z-index: 999;
	background: white;
	overflow: hidden !important;

	border-radius: 17px;

	&__content {
		display: grid;
		grid-template-columns: 300px 1fr;
		position: relative;
		overflow: hidden;

		&-logo {
			width: 80px;
			height: 80px;

			margin-left: 70px;
			margin-top: 50px;
		}
		&-app {
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}

	&__content-1 {
		background: url("/public/assets/images/modal-bg.svg");
		background-repeat: no-repeat;
	}
	&__content-2 {
		padding: 1rem 1rem 0 1rem;

		h2 {
			color: $blue-color;
			font-weight: bold;
			font-size: 2rem;
		}

		p {
			color: rgba(0, 0, 0, 0.6);
			font-size: 1.1rem;
		}
	}

	&__content-group {
		display: flex;
		align-items: center;
		gap: 1rem;
		margin-bottom: 1.4rem;
	}
	&__content-button {
		min-width: 40px;
		min-height: 40px;
		width: 40px;
		height: 40px;
		background: #e3eeee;

		display: flex;
		align-items: center;
		justify-content: center;

		border-radius: 50%;
	}

	&__footer {
		background: #ebecf4;
		padding: 1.8rem;

		border-top-left-radius: 20px;
		border-top-right-radius: 20px;

		h4 {
			color: $blue-color;
			font-weight: bold;
			font-size: 1.2rem;

			margin-bottom: 1.6rem;
		}

		&-group {
			display: flex;
			align-items: center;
			gap: 1rem;

			margin-bottom: 1rem;
			color: rgba(0, 0, 0, 0.7);
		}
		&-button {
			background: $blue-color;
			border-radius: 50%;

			color: white;
			font-size: 0.9rem;

			display: flex;
			align-items: center;
			justify-content: center;

			min-width: 25px;
			min-height: 25px;
			max-width: 25px;
			max-height: 25px;
		}

		&-login {
			margin-top: 1.2rem;

			padding-bottom: 1.3rem;

			display: flex;
			align-items: center;
			gap: 1.5rem;

			> * {
				display: flex;
				align-items: center;
			}

			strong {
				display: inline-block;
				margin-inline: 0.3rem;
				color: $blue-color;
			}
		}
	}

	@media (max-width: 900px) {
		&__content {
			grid-template-columns: 1fr;
		}
		&__content-1 {
			display: none;
		}
		&__content-2 {
			h2 {
				font-size: 1.5rem;
				word-break: break-word;
			}
		}

		&__footer {
			&-login {
				flex-wrap: wrap;
			}
		}
	}
}
</style>

<template>
    <v-navigation-drawer
        :mini-variant-width="70"
        :permanent="!isMobile"
        :expand-on-hover="!isMobile"
        v-model="isMenuOpen"
        app
        :style="{ background: 'var(--main-color)', height: '100%' }"
    >
      <v-list class="px-2">
        <v-list-item
            v-for="(menu, i) in menus"
            :key="'menu-professor-' + i"
            @click="onClickFunction(menu)"
            class="white--text"
            :class="{
            'lista--ativa': rotaAtiva(menu.rota),
            'mb-3': menu.titulo === 'Livros',
            'menu-meu-perfil': menu.titulo === 'Meu Perfil',
          }"
        >
          <v-list-item-icon class="d-flex align-center justify-center mx-0 my-2">
            <v-img max-width="30" max-height="30" :src="renderIcon(menu.icon)"/>
            <div v-if="menu.titulo === 'Livros'" class="divisor"/>
          </v-list-item-icon>
  
          <v-list-item-title class="ms-5" :style="{ whiteSpace: 'normal' }">
            {{ menu.titulo }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
  
      <!--      <v-list class="px-2" v-show="privilegio == 5">-->
      <!--        <v-list-item-->
      <!--            v-for="(menu, i) in menusConteudo"-->
      <!--            :key="'menu-conteudo-' + i"-->
      <!--            @click="clickAcaoMenu(menu)"-->
      <!--            class="white&#45;&#45;text"-->
      <!--            :class="{'lista&#45;&#45;ativa': rotaAtiva(menu.rota), 'mb-3': menu.titulo === 'Relatório das Questões'}"-->
      <!--        >-->
      <!--          <v-list-item-icon class="my-auto">-->
      <!--            &lt;!&ndash; <component :is="menu.icon" color="#fff" /> &ndash;&gt;-->
      <!--            <v-img max-width="30" max-height="30" :src="menu.icon"/>-->
      <!--            <div v-if="menu.titulo === 'Relatório das Questões'" class="divisor"></div>-->
      <!--          </v-list-item-icon>-->
  
      <!--          <v-list-item-title class="my-2" :style="{ whiteSpace: 'normal' }">-->
      <!--            {{ menu.titulo}}-->
      <!--          </v-list-item-title>-->
      <!--        </v-list-item>-->
      <!--      </v-list>-->
  
      <ModalSair
          :modal-aberto="modalSairAberto"
          @close-modal="modalSairAberto = false"
          @sair="
            modalSairAberto = false;
            $router.push('/sair');"
          @continuar="modalSairAberto = false"
      />
    </v-navigation-drawer>
  </template>
  
  <script>
  import alerts from "@/alerts";
  import ModalSair from "@/components/ModalSair.vue";
  
  export default {
    name: "NavbarProfessor",
    props: ["toggleMenu", "modalAppAgenda", "user"],
    mixins: [alerts],
    data() {
      return {
        isMenuOpen: false,
        modalSairAberto: false,
  
        menus: [
          {
            titulo: "Ajuda",
            icon: "/assets/icones/menu-faq.svg",
            rota: "/professor/faq",
          },
          {
            titulo: "Sair",
            icon: "/assets/icones/aluno/menu-sair.svg",
            // icon: Logout,
            rota: "sair",
            onClickFunction: () => {
              this.sair();
            },
          },
        ],
      };
    },
    methods: {
      rotaAtiva(rota) {
        return this.$route.fullPath.indexOf(rota) !== -1;
      },
      renderIcon(icon) {
        return `${icon}`;
      },
      onClickFunction(menu) {
        if (menu.onClickFunction) {
          menu.onClickFunction();
        } else {
          this.clickAcaoMenu(menu);
        }
      },
      clickAcaoMenu(menu) {
        if (menu.rota == this.$router.currentRoute.path) {
          return;
        }
        if (menu.rota != "#") {
          this.$router.push(menu.rota);
        } else {
          this.modalPaginaConstrucaoAberto = true;
        }
      },
  
      redirectToFaq() {
        // Redirect to the '/about' route
        this.$router.push("./src/pages/professor/faqProfessor/FaqPage.vue/about");
      },
  
      sair() {
        this.modalSairAberto = true;
      },
      
      adjustModuleByEnvironmentHoraAprenderFund1() {
        if (!this.user["obra"]) {
          return;
        }
        if (this.user["obra"] != 4) {
          return;
        }
        
        const isNotExistSchollYear = !this.user["anos"];

        if (isNotExistSchollYear) {
          return;
        }
        const anos = [
          '1º ano',
          '2º ano',
          '3º ano',
          '4º ano',
          '5º ano'
        ]
        const fundamental1 = this.user["anos"].some((item) =>
          anos.every((anoNome) => item.nome === anoNome) 
        );
  
        if (fundamental1) {
          return;
        }
  
        // eslint-disable-next-line vue/no-mutating-props
        this.user.modulos = this.user.modulos
          .map((modulo) => {
            if (modulo.titulo === "Modelagem de Avaliações e Atividades") {
              return {
                ...modulo,
                titulo: "Modelagem de Atividades",
                rota: "/professor/atividade/criar"
              }
            }
            return modulo;
          })
          .filter((modulo) => modulo.titulo !== "Minhas Turmas");
      },

      adjustModuleByEnvironmentHoraAprender() {
        if (!this.user["obra"]) {
          return;
        }
        if (this.user["obra"] != 4) {
          return;
        }
        
        const isNotExistSchollYear = !this.user["anos"];

        if (isNotExistSchollYear) {
          return;
        }

        const kindergartenTeach = this.user["anos"].every((item) =>
            item.nome.toLowerCase().includes("grupo")
        );
  
        if (kindergartenTeach) {
          return;
        }

        const ignoreModules = [
          "Meus Arquivos",
          "Álbuns de Fotos",
          "Minhas Turmas"
        ];
  
        // eslint-disable-next-line vue/no-mutating-props
        this.user.modulos = this.user.modulos.filter(
            ({titulo}) => !ignoreModules.some((modulo) => modulo === titulo )
        );
      },
  
      adjustModuleForChildEducation() {
        const isNotExistSchollYear = !this.user["anos"]
        if (isNotExistSchollYear) {
          return;
        }

        const kindergartenTeach = this.user["anos"].every((item) =>
            item.nome.toLowerCase().includes("grupo")
        );
  
        if (!kindergartenTeach) {
          return;
        }

        const ignoreModules = [
          "Meus Arquivos",
          "Álbuns de Fotos",
          "Modelagem de Avaliações e Atividades",
          "Modelagem de Atividades"
        ];
  
        // eslint-disable-next-line vue/no-mutating-props
        this.user.modulos = this.user.modulos.filter(
            ({titulo}) => !ignoreModules.some((modulo) => modulo === titulo )
        );
      },
    },
    watch: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.width <= 600;
      },
    },
    created() {
      this.isMenuOpen = this.menuOpen;
  
      //  Modelagem de Avaliações e Atividades
      this.adjustModuleForChildEducation();
      this.adjustModuleByEnvironmentHoraAprender();
      this.adjustModuleByEnvironmentHoraAprenderFund1();
      this.menus = this.user.modulos.concat(this.menus);
    },
    components: {ModalSair},
  };
  </script>
  
  <style lang="css">
  @media (max-width: 600px) {
    .menu-aside {
      z-index: 202;
    }
  }
  </style>

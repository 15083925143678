import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import icones from "../icones";

Vue.use(Vuetify);

const theme = {
  // cores principais
  azul: "#0d47a1",
  roxo: "#4B089F",
  lilas: "#b899e0",
  black500: "#1C1C1C",
  corSistema: "#E63B50",
  cinza500: "#4C4C4C",
  cinza400: "#C0C0C0",
  cinzaInputBackground: "#969696",
  red500: "#F42500",
  white: "#FFF",
  orange: "#e58c05",
  tagDisponivel: "#527B22",
  tagAguardando: "#8A8A8A",
  bordaDisponivel: "#F6F8F4",
  bordaAguardando: "#F3f3f3",
  disabled: "#9A9A9A",
};

const vuetify = new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  icons: {
    iconfont: "mdi",
    values: {
      home: {
        component: "home",
      },
      grafico: {
        component: "grafico",
      },
      chapeu: {
        component: "chapeu",
      },
      play: {
        component: "play",
      },
      arquivo: {
        component: "arquivo",
      },
      interrogacao: {
        component: "interrogacao",
      },
      sair: {
        component: "sair",
      },
      cadeado: {
        component: "cadeado",
      },
      calendario: {
        component: "calendario",
      },
      setinha_direita: {
        component: "setinha_direita",
      },
      setinha_esquerda: {
        component: "setinha_esquerda",
      },
      bolinha: {
        component: "bolinha",
      },
    },
  },
});

export default vuetify;

import { render, staticRenderFns } from "./ModalPadrao.vue?vue&type=template&id=4c92d234&scoped=true"
import script from "./ModalPadrao.vue?vue&type=script&lang=js"
export * from "./ModalPadrao.vue?vue&type=script&lang=js"
import style0 from "./ModalPadrao.vue?vue&type=style&index=0&id=4c92d234&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c92d234",
  null
  
)

export default component.exports
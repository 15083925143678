<template>
  <v-navigation-drawer
    :mini-variant-width="70"
    app
    :permanent="!isMobile"
    :expand-on-hover="!isMobile"
    v-model="isMenuOpen"
    :style="{ background: 'var(--main-color)', height: '100%' }"
  >
    <v-list>
      <div v-for="(menu, i) in menus" :key="'menu-professor-' + i">
        <list-item
          v-if="!menu.group"
          :item="menu"
          @modalAppAgenda="$emit('modalAppAgenda')"
          @modalSair="$emit('modalSair')"
          @action="clickAcaoMenu"
        />

        <list-group-item
          v-else
          @modalAppAgenda="$emit('modalAppAgenda')"
          :menu="menu"
        />
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import ListItem from "./ListItem";
import ListGroupItem from "./ListGroupItem";

export default {
  name: "NavbarGestor",
  props: ["toggleMenu", "modalAppAgenda", "user"],
  components: { ListItem, ListGroupItem },
  data() {
    return {
      isMenuOpen: false,
      menus: [
        {
          icon: "",
          titulo: "Minha Escola",
          rota: "/gestor/minha-escola",
          ativo: false,
        },
        {
          icon: require("@assets/icones/menu-home.svg"),
          titulo: "Home",
          rota: "/gestor/home",
          ativo: false,
        },
        {
          titulo: "Comunicação",
          icon: require("@assets/icones/menu-comunicacao.png"),
          group: true,
          itens: [
            {
              icon: require("@assets/icones/menu-comunicado.png"),
              titulo: "Comunicados",
              rota: "/gestor/comunicados",
              ativo: false,
            },
            {
              icon: require("@assets/icones/menu-eventos.svg"),
              titulo: "Eventos",
              rota: "/gestor/agenda",
              ativo: false,
            },
            {
              icon: require("@assets/icones/menu-smartphone.png"),
              titulo: "App Agenda",
              ativo: false,
              rota: "modalAppAgenda",
              exibir: "n",
            },
            {
              icon: require("@assets/icones/menu-boletim.png"),
              titulo: "Boletim",
              rota: "/gestor/cadastro/boletim",
              ativo: false,
            },
          ],
        },
        {
          icon: require("@assets/icones/menu-configuracoes_de_acesso.svg"),
          titulo: "Configuração de Acesso",
          rota: "/gestor/configuracao-acesso",
          ativo: false,
        },
        {
          titulo: "Relatórios",
          icon: require("@assets/icones/relatorio.png"),
          group: true,
          itens: [
            {
              icon: require("@assets/icones/menu-acessos.png"),
              titulo: "Relatório de Acessos",
              rota: "/gestor/relatorio/acessos",
              ativo: false,
            },
            {
              icon: require("@assets/icones/menu-acessos.png"),
              titulo: "Relatórios Bimestrais",
              rota: "/gestor/relatorio/bimestral",
              ativo: false,
            },
          ],
        },
        {
          titulo: "Modelagem de Avaliações e Atividades",
          icon: require("@assets/icones/menu-modelagem.svg"),
          group: true,
          itens: [
            {
              icon: require("@assets/icones/menu-livro.svg"),
              titulo: "Atividades",
              rota: "/gestor/atividades",
              ativo: false,
            },
            {
              icon: require("@assets/icones/menu-banco-itens.svg"),
              titulo: "Avaliações",
              rota: "/gestor/avaliacoes",
              ativo: false,
            },
          ],
        },
        {
          icon: require("@assets/icones/menu-marketing-educacional.png"),
          titulo: "Marketing Educacional",
          rota: "/gestor/marketing-educacional",
          ativo: false,
        },
        {
          icon: require("@assets/icones/menu-turmas.svg"),
          titulo: "Professores e Turmas",
          rota: "/gestor/professores-turma",
          ativo: false,
        },
        {
          icon: require("@assets/icones/menu-assessoria.svg"),
          titulo: "Assessoria Pedagógica",
          rota: "/professor-gestor/assessoria",
          ativo: false,
        },
        {
          icon: require("@assets/icones/menu-recursos-educacionais.svg"),
          titulo: "Recursos Educacionais Digitais",
          rota: "/professor-gestor/recursos-educacionais",
          ativo: false,
        },
        {
          titulo: "Livros",
          icon: require("@assets/icones/menu-livro.svg"),
          rota: "/professor-gestor/livros-digitais",
          ativo: false,
        },
        {
          icon: require("@assets/icones/menu-album.svg"),
          titulo: "Álbuns de Fotos",
          rota: "/professor-gestor/albuns",
          ativo: false,
        },
        {
          titulo: "Meu Perfil",
          icon: require("@assets/icones/menu-perfil.svg"),
          rota: "/gestor/perfil",
        },
        {
          titulo: "Ajuda",
          icon: "/assets/icones/menu-faq.svg",
          rota: "/gestor/faq",
        },

        {
          titulo: "Sair",
          icon: require("@assets/icones/menu-sair.svg"),
          // icon: Logout,
          rota: "sair",
        },
      ],
    };
  },
  watch: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    user() {
      this.ajusteModulosParaHoradeAprender();
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width <= 600;
    },
    usuario() {
      return this.$store.getters["usuarioStore/user"];
    },
  },
  methods: {
    clickAcaoMenu(menu) {
      menu.action();
    },
    ajusteModulosParaHoradeAprenderApenasInfantil() {
      if (!this.user["obra"]) {
        return;
      }
      if (this.user["obra"] != 4) {
        return;
      }
      const kindergartenTeach = this.user["anos"].every((item) =>
        item.nome.toLowerCase().includes("grupo")
      );

      if (!kindergartenTeach) {
        return;
      }

      this.menus = [
        {
          icon: this.usuario.foto_escola,
          titulo: "Minha Escola",
          rota: "/gestor/minha-escola",
          ativo: false,
        },
        {
          icon: require("@assets/icones/menu-home.svg"),
          titulo: "Home",
          rota: "/gestor/home",
          ativo: false,
        },
        {
          icon: require("@assets/icones/menu-configuracoes_de_acesso.svg"),
          titulo: "Configuração de Acesso",
          rota: "/gestor/configuracao-acesso",
          ativo: false,
        },
        {
          icon: require("@assets/icones/menu-assessoria.svg"),
          titulo: "Assessoria Pedagógica",
          rota: "/professor-gestor/assessoria",
          ativo: false,
        },
        {
          icon: require("@assets/icones/menu-recursos-educacionais.svg"),
          titulo: "Recursos Educacionais Digitais",
          rota: "/professor-gestor/recursos-educacionais",
          ativo: false,
        },
        {
          titulo: "Livros",
          icon: require("@assets/icones/menu-livro.svg"),
          rota: "/professor-gestor/livros-digitais",
          ativo: false,
        },
       {
          titulo: "Ajuda",
          icon: "/assets/icones/menu-faq.svg",
          rota: "/gestor/faq",
        },
       {
          titulo: "Sair",
          icon: require("@assets/icones/menu-sair.svg"),
          // icon: Logout,
          rota: "sair",
        },
      ];
    },
    ajusteModulosParaHoradeAprenderComFundamentalI() {
      if (!this.user["obra"]) {
        return;
      }
      if (this.user["obra"] != 4) {
        return;
      }

      const kindergartenTeach = this.user["anos"].every((item) =>
        item.nome.toLowerCase().includes("grupo")
      );

      if (kindergartenTeach) {
        return;
      }

      this.menus = [
        {
          icon: this.usuario.foto_escola,
          titulo: "Minha Escola",
          rota: "/gestor/minha-escola",
          ativo: false,
        },
        {
          icon: require("@assets/icones/menu-home.svg"),
          titulo: "Home",
          rota: "/gestor/home",
          ativo: false,
        },
        {
          icon: require("@assets/icones/menu-configuracoes_de_acesso.svg"),
          titulo: "Configuração de Acesso",
          rota: "/gestor/configuracao-acesso",
          ativo: false,
        },
        {
          titulo: "Modelagem de atividades",
          icon: require("@assets/icones/menu-modelagem.svg"),
          group: true,
          itens: [
            {
              icon: require("@assets/icones/menu-livro.svg"),
              titulo: "Atividades",
              rota: "/gestor/atividades",
              ativo: false,
            },
          ],
        },

        {
          icon: require("@assets/icones/menu-assessoria.svg"),
          titulo: "Assessoria Pedagógica",
          rota: "/professor-gestor/assessoria",
          ativo: false,
        },
        {
          icon: require("@assets/icones/menu-recursos-educacionais.svg"),
          titulo: "Recursos Educacionais Digitais",
          rota: "/professor-gestor/recursos-educacionais",
          ativo: false,
        },
        {
          titulo: "Livros",
          icon: require("@assets/icones/menu-livro.svg"),
          rota: "/professor-gestor/livros-digitais",
          ativo: false,
        },
        {
          titulo: "Ajuda",
          icon: "/assets/icones/menu-faq.svg",
          rota: "/gestor/faq",
        },
        {
          titulo: "Sair",
          icon: require("@assets/icones/menu-sair.svg"),
          rota: "sair",
        },
      ];
    },
  },
  created() {
    this.isMenuOpen = this.menuOpen;
    this.ajusteModulosParaHoradeAprenderApenasInfantil();
    this.ajusteModulosParaHoradeAprenderComFundamentalI();
    this.menus[0].icon = this.usuario.foto_escola;
  },
};
</script>

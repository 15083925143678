import Vue from "vue";
import VuePlyr from "vue-plyr";
import App from "./components/app/App";
import router from "./routes/router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { VueMaskDirective } from "v-mask";
import { Settings } from "luxon";
import "vue-plyr/dist/vue-plyr.css";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

// import Fragment from "vue-fragment";
import VueDragscroll from "vue-dragscroll";

import AOS from "aos";
import "aos/dist/aos.css";

window.Echo = new Echo({
	broadcaster: "pusher",
	key: "myappkey",
	wsHost: process.env.VUE_APP_WEBSOCKET,
	wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
	wssPort: process.env.VUE_APP_WEBSOCKET_SSL_PORT,
	forceTLS: true,
	disableStats: true,
});

Vue.config.productionTip = false;

Vue.use(VueDragscroll);

Vue.use(VuePlyr, {
	plyr: {},
});
Vue.directive("mask", VueMaskDirective);
Vue.directive("qa", {
	bind: (el, binding) => {

		if (binding.arg === 'js') {
			el.classList.add(binding.value)
			return;
		}

		if (Reflect.ownKeys(binding.modifiers).length === 0) {
			el.classList.add(binding.arg)
			return;
		}

		if (binding.modifiers.label) {
			const $label = el.querySelector(binding.value);

			if (!$label) {
				return;
			}

			const idIpunt = $label.getAttribute('for');
			const $input = el.querySelector(`#${idIpunt}`);

			if (!$input) {
				return;
			}

			$input.classList.add(binding.arg);
			return;
		}

		if (binding.modifiers.query) {
			if (Object.prototype.toString.call(binding.value).toLowerCase().includes('array]')) {
				const $els = binding.value.map((i) => el.querySelector(i));
				$els.forEach((i) => i.classList.add(binding.arg));
				return;
			}

			const $el = el.querySelector(binding.value)
			$el.classList.add(binding.arg);
			return;
		}
	}
});
Settings.defaultLocale = "pt-br";

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
	mounted() {
		AOS.init();
	},
}).$mount("#app");

<template>
  <button @click="$emit('button-click')" type="button" class="button">
    <slot></slot>
  </button>
</template>

<script>
export default {
  emits: ["button-click"],
};
</script>

<style scoped lang="scss">
.button {
  border-radius: 30px;
  padding: 10px 30px;
  color: white;
  font-weight: 600;
  max-width: 60%;
  background: linear-gradient(var(--main-gradient));
  border-bottom: 5px solid var(--main-color);
}
</style>

<template>
  <BaseModal
    :modal-aberto="modalSairPlataformaAberto"
    @close-modal="fechar()"
    justify="center"
  >
    <template v-slot:imagem>
      <img src="/assets/images/aluno/sair.png" alt="" />
    </template>
    <template v-slot:descricao>
      <h3 class="mt-6 mb-4">Você realmente deseja sair da plataforma?</h3>
    </template>
    <template v-slot:buttons>
      <div class="d-flex justify-center">
        <ButtonModal @button-click="fechar()" class="me-3">
          {{ descricaoButton }}
        </ButtonModal>
        <ButtonModal @button-click="$emit('sair')"> Sair </ButtonModal>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import ButtonModal from "./ButtonModal.vue";
import BaseModal from "./BaseModal.vue";
export default {
  props: {
    modalAberto: {
      type: Boolean,
    },

    descricaoButton: {
      type: String,
      default: "Cancelar",
    },
  },
  components: {
    ButtonModal,
    BaseModal,
  },
  computed: {
    modalSairPlataformaAberto() {
      return this.modalAberto;
    },
  },
  methods: {
    fechar() {
      this.$emit('close-modal');
    }
  }
};
</script>

<style></style>

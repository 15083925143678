import api from "../api";

export default {
  namespaced: true,
  state: {
    usuario: { nome: "", id: 0, id_privilegio: 0 },
  },
  getters: {
    privilegio(state) {
      return state.usuario.id_privilegio;
    },
    user(state) {
      return state.usuario;
    },
  },
  mutations: {
    //payload é um valor que é passado como parametro para alterar o state
    SET_USER(state, payload) {
      state.usuario = payload;
    },
    SET_USER_PROFILE(state, payload) {
      state.usuario.foto = payload;
    },
    RESET_USER(state) {
      state.usuario = { nome: "", id: 0, id_privilegio: 0 };
    },
  },
  actions: {
    //context é  como se tem acesso ao store nas actions
    async loadUser(context) {
      try {
        if (!context.state.usuario.id) {
          let dados = await api.get("/get-usuario");
          context.commit("SET_USER", dados.data);
          return context.state.usuario;
        }

        return context.state.usuario;
      } catch (e) {
        return context.state.usuario;
      }
    },
    setUserProfile(context, payload) {
      context.commit("SET_USER_PROFILE", payload);
    },
    resetUser(context) {
      context.commit("RESET_USER");
    },
  },
};

<template>
  <BaseModal
    v-if="isModalOpen"
    :modal-aberto="isModalOpen"
    @close-modal="$emit('close-modal')"
  >
    <template v-slot:imagem>
      <img
        width="90%"
        src="/assets/images/aluno/maintenance.gif"
        alt="Imagem"
      />
    </template>

    <template v-slot:descricao>
      <h2 class="modal-text">
        {{mensagem}}
      </h2>
    </template>

    <template v-slot:buttons>
      <div class="d-flex justify-center">
        <v-btn
          @click="$emit('close-modal')"
          color="var(--main-color)"
          rounded
          dark
          v-text="'Ok'"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
export default {
  name: "ModalPaginaConstrucao",
  emits: ["closeModal"],
  props: {
    modalAberto: {
      type: Boolean,
      validator: (value) => value === true || value === false,
    },

    mensagem: {
      default: 'O conteúdo dessa página ainda ainda esta em desenvolvimento'
    }
  },
  computed: {
    isModalOpen() {
      return this.modalAberto;
    },
  },
  components: { BaseModal },
};
</script>
<style scoped lang="scss"></style>

<template>
  <v-dialog
      v-model="getIsModalOpen"
      @click:outside="fechar()"
      @keydown.esc="fechar()"
      max-width="980"
  >
    <div class="modal modal-app">
      <div class="modal__content">
        <div class="modal__header">
          <div class="d-flex justify-end">
            <v-btn
                @click="fechar()"
                class="ms-3"
                small
                fab
                color="#CE0000"
            >
              <v-icon color="#fff"> mdi-close</v-icon>
            </v-btn>
          </div>

          <h2>App Agenda Evoluir</h2>
          <p class="text mb-6">
            Com o aplicativo evoluir, o responsável tem acesso à toda rotina
            escolar do seu filho.
          </p>
        </div>
        <div class="modal__text pb-0">
          <v-row class="modal__images align-center pb-0">
            <v-col md="4" class="pb-0">
              <ul class="modal__lista px-0 mt-5">
                <li>
                  <img
                      class="icone"
                      src="/assets/images/agenda/message.svg"
                      alt=""
                  />
                  <p class="mb-0">
                    Mensagens enviadas por todos os setores da escola
                  </p>
                </li>

                <li>
                  <img
                      class="icone"
                      src="/assets/images/agenda/calendar.png"
                      alt=""
                  />
                  <p class="mb-0">Calendário de eventos e atividades escolares</p>
                </li>
                <li>
                  <img
                      class="icone"
                      src="/assets/images/agenda/sino.svg"
                      alt=""
                  />
                  <p class="mb-0">
                    Notificações de novos eventos, mensagens, atividades e
                    frequência do estudante
                  </p>
                </li>
              </ul>
            </v-col>
            <v-col
                md="8"
                class="d-flex justify-center container-images mt-5 mt-md-0 justify-md-end pb-0"
            >
              <img
                  src="/assets/images/agenda/dispositivo1.png"
                  alt="Imagem Dispositivo"
              />
              <img
                  src="/assets/images/agenda/dispositivo2.png"
                  alt="Imagem Dispositivo"
              />
              <img
                  src="/assets/images/agenda/dispositivo3.png"
                  alt="Imagem Dispositivo"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalInfoApp",
  props: {
    modalAberto: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    fechar() {
      this.$emit('close-modal');
    }
  },
  computed: {
    getIsModalOpen() {
      return this.modalAberto;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/sass/_utils" as utils;

ul {
  list-style: none;
}

.text {
  color: #1c1c1c;
  font-size: 1rem;
  opacity: 0.6;
}

.v-dialog {
  overflow: hidden !important;
}

.container-images {
  position: relative;
  right: -15px;

  img {
    display: inline-block;
    margin-top: 30px;
  }

  img:nth-of-type(1) {
    position: relative;
    right: -20px;
    bottom: -30px;
    @media (max-width: 500px) {
      bottom: 0px;
    }
  }

  img:nth-last-of-type(1) {
    position: relative;
    left: -15px;
    bottom: -30px;

    @media (max-width: 500px) {
      bottom: 0px;
    }
  }
}

@media (max-width: 700px) {
  img {
    width: 150px;
    height: 250px;
  }
}

@media (max-width: 500px) {
  .container-images {
    align-items: flex-end;
    min-width: 100%;
    overflow: scroll;
  }
}

.modal {
  z-index: 999;
  background: white;
  overflow: hidden !important;

  border-radius: 17px;

  &__header {
    background: url("/public/assets/images/agenda/effect.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px;

    height: 340px;

    border-radius: inherit;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;

    h2 {
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 48px;

      color: #d8deff;

      margin-bottom: 8px;
    }

    p.text {
      color: #d8deff;
      opacity: 0.8;
    }
  }

  &__images {
    margin-top: -150px;
    @include utils.responsivo(sm) {
      & {
        margin-top: 0px;
      }
    }
  }

  &__container {
    background: white;
  }

  &__content {
    height: auto;

    overflow: hidden;
    border-radius: 17px;
    background: white;

    @include utils.responsivo(xs) {
      & {
        max-width: 100%;
      }
    }
  }

  &__text {
    img.icone {
      min-width: 25px;
      max-width: 25px;
      min-height: 25px;
      max-height: 25px;
      object-fit: contain;
      margin-right: 10px;
    }
  }

  &__button {
    position: absolute;
    right: 20px;
    top: 20px;

    color: white;
  }

  &__text {
    width: 100%;

    ul > li {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      color: #000000;
      opacity: 0.7;
      font-size: 18px;
      line-height: 21px;
    }

    ul {
      margin-top: 20px;
      margin-left: 40px;

      > li {
        margin-top: 40px;
        margin-bottom: 10px;
        color: #3f3f3f;
        font-weight: 500;
        font-size: 1.1rem;

        display: flex;
        align-items: center;
      }
    }
  }
}
</style>

<template>
  <BaseModal
    :modal-aberto="modalAberto"
    @close-modal="fechar()"
    justify="center"
    max-width="650"
  >
    <template v-slot:imagem>
      <img width="320" :src="imagemComputed" alt="" />
    </template>
    <template v-slot:descricao>
      <h2
        style="line-height: 33px"
        class="mt-6 text-center black500--text font-weight-bold"
      >
        {{ dadosModal.texto }}
      </h2>
      <p class="mt-3 text-center black500--text mx-10" style="opacity: 0.7">
        {{ dadosModal.paragrafo }}
      </p>
      <div v-html="dadosModal.html"></div>
    </template>
    <template v-slot:buttons>
      <div class="d-flex justify-center">
        <v-btn
          class="ok-button-qa mr-4 white--text"
          color="var(--main-color)"
          @click="fechar()"
        >
          Ok
        </v-btn>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";
import successIcon from "@assets/images/success.svg";
import errorIcon from "@assets/images/error.svg";
import warningIcon from "@assets/images/warning.svg";

export default {
  name: "ModalSucessoErroWarning",
  emits: ["close-modal"],
  components: {
    BaseModal,
  },
  props: {
    modalAberto: {
      type: Boolean,
      required: true,
    },
    dadosModal: {
      type: Object,
      required: true,
    },
    rotaSucesso: {
      type: String,
      default: "",
    },
    rotaErro: {
      type: String,
      default: "",
    },
    rotaWarning: {
      type: String,
      default: "",
    },
  },
  methods: {
    fechar() {
      const rota =
        this.dadosModal.tipo === "sucesso"
          ? this.rotaSucesso
          : this.dadosModal.tipo === "erro"
          ? this.rotaErro
          : this.rotaWarning;
      if (rota) {
        this.redirecionar(rota);
      }
      this.$emit("close-modal");
    },
    redirecionar(rota) {
      this.$router.push(rota);
    },
  },
  computed: {
    imagemComputed() {
      if (this.dadosModal.tipo === "sucesso") {
        return successIcon;
      } else if (this.dadosModal.tipo === "erro") {
        return errorIcon;
      } else if (this.dadosModal.tipo === "warning") {
        return warningIcon;
      }
      return "";
    },
  },
  mounted() {},
};
</script>

import api from "../api";
export default {
    namespaced: true,
    state: {
        notificacoes: [],
    },

    mutations: {
        //payload é um valor que é passado como parametro para alterar o state
        SET_NOTIFICATION(state, payload) {
            state.notificacoes = payload;
        },
    },

    actions: {
        //context é  como se tem acesso ao store nas actions
        async loadNotifications(context) {
            // let dados = await api.get("/get-notification");
            let dados = [
                {
                    id: 1,
                    nome: "Você tem um novo evento escolar ",
                    descricao:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hendrerit urna risus et at at elementum tristique integer imperdiet. Vitae nisl viverra lectus laoreet sed.",
                    icone: require("@/assets/icones/home-house.svg"),
                },
            ];

            context.commit("SET_NOTIFICATION", dados);
        },
    },
};

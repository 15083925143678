import Vue from "vue";
import Vuex from "vuex";
import usuarioStore from "./usuarioStore";
import notificacoesStore from "./notificacoesStore";
import loadingStore from '../store/loadingStore'
import alertStore from "./alertStore";

Vue.use(Vuex);

const store = {
    modules: {
        usuarioStore,
        notificacoesStore,
        loadingStore,
        alertStore
    },
};

export default new Vuex.Store(store);

let prefixoProfessor = "/professor";
const privilegioProfessor = [2];
import auth from "../middleware/auth";

import routesSeusArquivos from "./professor/seusArquivos";

const routesGeral = [
	{
		path: prefixoProfessor + "/home",
		name: "home-professor",
		component: () => import("../pages/professor/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
	{
		path: prefixoProfessor + "/blog",
		name: "blog-professor",
		component: () => import("../pages/professor/blog/Blog.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
	{
		path: prefixoProfessor + "/blog/artigo",
		name: "artigo-blog",
		component: () => import("../pages/professor/blog/VerArtigo.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},

	//Videoaulas
	{
		path: prefixoProfessor + "/videoaulas",
		name: "videoaulas",
		component: () => import("../pages/professor/videoaulas/Videoaulas.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
	//Suporte
	{
		path: prefixoProfessor + "/suporte",
		name: "suporte",
		component: () => import("../pages/professor/suporte/Inicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
	{
		path: prefixoProfessor + "/suporte/tema-selecionado",
		name: "suporte-tema-selecionado",
		component: () => import("../pages/professor/suporte/TemaSelecionado.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
	{
		path: prefixoProfessor + "/suporte/duvida",
		name: "suporte-duvida",
		component: () => import("../pages/professor/suporte/Duvida.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},

	//Formação
	{
		path: prefixoProfessor + "/formacao",
		name: "formacao-inicio",
		component: () => import("../pages/professor/formacao/Inicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
	{
		path: prefixoProfessor + "/formacao/videoaulas",
		name: "assistir-videoaula-formacao",
		component: () => import("../pages/professor/formacao/Videoaulas.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},

	//Calendário
	{
		path: prefixoProfessor + "/calendario",
		name: "calendario",
		component: () => import("../pages/professor/Calendario.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},

	//Meus alunos
	{
		path: prefixoProfessor + "/meus-alunos",
		name: "meus-alunos",
		component: () => import("../pages/professor/alunos/Inicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},

	//Aulas - Componente curricular

	{
		path: prefixoProfessor + "/materiais/componentes-curriculares",
		name: "componentes-curriculares",
		component: () => import("../pages/professor/componentesCurriculares/ComponentesCurricularesInicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},


	//Minhas Turmas
	{
		path: prefixoProfessor + "/agenda",
		name: "agenda",
		component: () => import("../pages/professor/agenda/AgendaInicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},

	//SALA DE AULA
	{
		path: prefixoProfessor + "/sala-aula/escola/:id_escola/turma/:id_turma",
		name: "sala-aula",
		component: () => import("../pages/professor/salaAula/MainSalaAula.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},

	//PERFIL
	{
		path: prefixoProfessor + "/perfil",
		name: "perfil",
		component: () => import("../pages/professor/perfil/PerfilInicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
	//PLANEJAMENTO ESCOLAR
	// {
	//     path: prefixoProfessor + "/planejamento-escolar",
	//     name: "perfil",
	//     component: () =>
	//         import("../pages/professor/planejamentoEscolar/PlanejamentoEscolarInicio.vue"),
	//     beforeEnter: auth,
	// },

	//Recursos Educacionais
	{
		path:
			prefixoProfessor +
			"/recursos-educacionais/avaliacao/:id_avaliacao/inserir-respostas/escola/:id_escola/turma/:id_turma",
		name: "inserir-respostas-avaliacao-recursos-educacionais",
		component: () => import("../pages/professor/recursosEducacionais/avaliacoes/detalhes/InserirResposta.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
	{
		path:
			prefixoProfessor + "/recursos-educacionais/avaliacao/:id_avaliacao/resultados/escola/:id_escola/turma/:id_turma",
		name: "resultados-avaliacao",
		component: () => import("../pages/professor/recursosEducacionais/avaliacoes/detalhes/Resultados.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},

	{
		path: prefixoProfessor + "/sala-professores",
		name: "sala-professores-professor",
		component: () => import("../pages/professor/salaProfessores/SalaProfessoresInicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},

	//livros-digitais
	{
		path: prefixoProfessor + "/jogo",
		name: "jogo",
		component: () => import("../pages/professor/TelaJogo.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
	//Editor Atividades
	{
		path: prefixoProfessor + "/editor-atividades",
		name: "editor-atividades",
		component: () => import("../pages/professor/editorAtividades/EditorAtividadesInicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
	{
		path: prefixoProfessor + "/gerador-questao",
		name: "gerador-questao",
		component: () => import("../pages/professor/geradorQuestao/GeradorQuestaoInicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
	{
		path: prefixoProfessor + "/gerador-questao/visualizar-questao/:id_questao",
		name: "visualizar-questao",
		component: () => import("../pages/professor/geradorQuestao/VisualizarQuestao.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
	{
		path: prefixoProfessor + "/gerador-questao/editar-criar-questao/:id_questao?",
		name: "editar-criar-questao",
		component: () => import("../pages/professor/geradorQuestao/EditarCriarQuestao.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},

	{
		path: prefixoProfessor + "/planejamento",
		name: "planejamento-inicio",
		component: () => import("../pages/professor/planejamento/PlanejamentoInicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},

	{
		path: prefixoProfessor + "/planejamento/turma/:idTurma",
		name: "planejamento-turma",
		component: () => import("../pages/professor/planejamento/turma/PlanejamentoTurmaInicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
		props: true,
	},

	{
		path: prefixoProfessor + "/planejamento/topico/:idTopico/livros",
		name: "planejamento-livros",
		component: () => import("../pages/professor/planejamento/livros/PlanejamentoLivrosInicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
		props: true,
	},

	{
		path: prefixoProfessor + "/planejamento/criar",
		name: "planejamento-criar",
		component: () => import("../pages/professor/planejamento/criar/CriarInicio.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},

	{
		path: prefixoProfessor + '/issuu',
		name: 'LivrosEmbedIssuuProfessor',
		ttl: 'LivrosEmbedIssuuProfessor',
		icon: 'mdi-home-outline',
		meta: {
			id_privilegio: privilegioProfessor,
		},
		component: () => import('../pages/professor/LivrosEmbedIssuu.vue'),
		menu: false,
	},

	{
		path: prefixoProfessor + "/faq",
		name: "faq-professor",
		component: () => import("../pages/professor/faqProfessor/faqProfessor.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioProfessor,
		},
	},
];

let conjunto = routesGeral.concat(routesSeusArquivos);

export default conjunto;

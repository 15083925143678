import axios from "axios";
import store from "./store";
const API_URL = process.env.VUE_APP_API_URL;

const object = axios.create({
  baseURL: API_URL,
});

const requestHandler = async (request) => {
  try {
    //não precisa mais colocar o loading na page vue, ele já está sendo acionado aqui
    store.commit("loadingStore/SET_LOADING", true);
    let xyz = await window.localStorage.getItem("token");
    request.headers.Authorization = "Bearer " + xyz;
  } catch (e) {}

  return request;
};

const responseHandler = async (response) => {
  store.commit("loadingStore/SET_LOADING", false);
  return response;
};

const errorHandler = (error) => {
  store.commit("loadingStore/SET_LOADING", false);
  return Promise.reject(error);
};

const errorHandlerResponse = (error) => {
  store.commit("loadingStore/SET_LOADING", false);
  if (error.response.status == 401) {
    window.localStorage.removeItem("token");
    window.location.href = "/";
  }
  return Promise.reject(error);
};

object.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);
object.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandlerResponse(error)
);

export default object;

const objetos = {
  methods: {
    echoListen(user) {
      if (!user.id) {
        return;
      }
      window.Echo.channel(`user.${user.id}`).listen(".SendMessage", (e) => {
        window.Echo.leave(`user.${user.id}`);
        this.alertAnswer(
          "Sessão encerrada",
          "O mesmo usuário logou em outro navegador ou outro dispositivo.",
          "info"
        );
        this.$router.replace("/login");
      });
    },
  },
};
export default objetos;

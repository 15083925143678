let prefixoAssessoria = "/acesso";
const privilegioAssessoria = [7];
import auth from "../middleware/auth";

export default [
    {
        path: prefixoAssessoria + "/home",
        name: "acesso-home",
        component: () => import("../pages/acesso/Home.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegioAssessoria,
        },
    },

	{
		path: prefixoAssessoria + "/relatorio-acesso",
		name: "Relatorio-acesso",
		component: () => import("../pages/acesso/acessos/Home.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioAssessoria,
		},
	},

    {
        path: prefixoAssessoria + "/configuracao-acesso",
        name: "gestor-configuracao-acesso-acessoria",
        component: () => import("../pages/acesso/cadastro/Home.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegioAssessoria,
        },
    },

    //cruds assessoria

    {
        path: prefixoAssessoria + "/crud/encontros-pedagogicos",
        name: "Crud-Encontros-Pedagogicos-Acesso",
        component: () => import("../pages/acesso/crud/encontrosPedagogicos/Home.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegioAssessoria,
        },
        props: {
            tipo: "encontro",
        },
    },
    {
        path: prefixoAssessoria + "/crud/informacoes",
        name: "Crud-Informacoes-Acesso",
        component: () => import("../pages/acesso/crud/informacoes/Home.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegioAssessoria,
        },
        props: {
            tipo: "informacao",
        },
    },
    {
        path: prefixoAssessoria + "/crud/conteudo-apoio",
        name: "Crud-Conteudo-Apoio-Acesso",
        component: () => import("../pages/acesso/crud/conteudoApoio/Home.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegioAssessoria,
        },
    },

    {
        path: prefixoAssessoria + "/crud/marketing-educacional",
        name: "Crud-Marketing-Educacional-Acesso",
        component: () => import("../pages/acesso/crud/marketingEducacional/Home.vue"),
        beforeEnter: auth,
        meta: {
            id_privilegio: privilegioAssessoria,
        },
    },
    {
        path: prefixoAssessoria + '/issuu',
        name: 'LivrosEmbedIssuuAcesso',
        ttl: 'LivrosEmbedIssuuAcesso',
        icon: 'mdi-home-outline',
        meta: {
            id_privilegio: privilegioAssessoria,
        },
        component: () => import('../pages/acesso/LivrosEmbedIssuu.vue'),
        menu: false,
    },

];

import routesProfessor from "./professor.js";
import routesAluno from "./aluno";
import routesConteudo from "./conteudo";
import routesAcesso from "./acesso";
import routesGestor from "./gestor";
import routesProfessorGestor from "./professorGestor";
import apiNoToken from "@/api-no-token";

import VueRouter from "vue-router";
import Vue from "vue";
//teste

const routesGeral = [
  {
    path: "/",
    name: "Home",
    component: () => import("../pages/auth/login/Login"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../pages/auth/login/Login"),
  },
  {
    path: "/login2",
    name: "Login2",
    component: () => import("../pages/auth/login/Login"),
  },
  {
    path: "/pdf-viewer",
    name: "PdfViewer",
    component: () => import("../pages/PdfViewer.vue"),
  },
  {
    path: "/cadastro-aluno",
    name: "cadastro-aluno",
    component: () => import("../pages/auth/CadastroAluno"),
  },
  {
    path: "/cadastro-professor",
    name: "cadastro-professor",
    component: () => import("../pages/auth/CadastroProfessor"),
  },
  {
    path: "/recuperar-senha",
    name: "recupera-senha",
    component: () => import("../pages/auth/recuperarSenha/RecuperarSenha"),
  },
  {
    path: "/politica-privacidade",
    name: "politica-privacidade",
    component: () => import("../pages/PoliticaPrivacidade"),
  },
  {
    path: "/literarios",
    name: "literarios",
    component: () => import("../pages/literarios/Inicio.vue"),
  },
  {
    path: "/redirect-user",
    name: "RedirectUser",
    beforeEnter: (to, from, next) => {
      const { matricula, user_redirect_token } = to.query;
      apiNoToken.get(`/trocar-perfil-ambientes?matricula=${matricula}&user_redirect_token=${user_redirect_token}`)
        .then(response => {
          if (response.data.success) {
            const responseToken = response.data.token.original.access_token;
            const responseUsuario = JSON.stringify(response.data.token.original.user);
            localStorage.setItem("token", responseToken);
            localStorage.setItem("usuario", responseUsuario);
            next(response.data.rota);
          } else {
            next('/');
          }
        })
        .catch(error => {
          next('/');
        });
    }
  },
  {
    path: "*",
    redirect: "/",
  },
];

let conjunto = routesGeral.concat(
  routesProfessorGestor,
  routesProfessor,
  routesAluno,
  routesConteudo,
  routesAcesso,
  routesGestor,
);
Vue.use(VueRouter);

const router = new VueRouter({
  routes: conjunto,
  mode: "history",
});

export default router;

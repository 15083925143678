let prefixoGestor = "/gestor";
const privilegioGestor = [6];
import auth from "../middleware/auth";

export default [
  {
    path: prefixoGestor + "/home",
    name: "gestor-home",
    component: () => import("../pages/gestor/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  {
    path: prefixoGestor + "/professores-turma",
    name: "gestor-professor-turma",
    component: () =>
      import("../pages/gestor/professorTurma/ProfessorTurma.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + "/perfil",
    name: "gestor-perfil",
    component: () => import("../pages/gestor/Perfil.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  //SALA DE AULA
  {
    path: prefixoGestor + "/sala-aula/escola/:id_escola/turma/:id_turma",
    name: "sala-aula-gestor",
    component: () => import("../pages/gestor/salaAula/MainSalaAula.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  //SALA DOS PROFESSORES
  {
    path: prefixoGestor + "/sala-professores",
    name: "sala-professores",
    component: () =>
      import("../pages/gestor/salaProfessores/SalaProfessoresInicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  {
    path: prefixoGestor + "/agenda",
    name: "gestor-agenda",
    component: () => import("../pages/gestor/agenda/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + "/cadastro/boletim",
    name: "gestor-cadastro-professor",
    component: () => import("../pages/gestor/cadastro/boletim/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + "/minha-escola",
    name: "gestor-cadastro-escola",
    component: () => import("../pages/gestor/minhaEscola/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + "/relatorio/acessos",
    name: "gestor-relatorio-acesso",
    component: () => import("../pages/gestor/relatorio/acessos/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + "/relatorio/bimestral",
    name: "gestor-relatorio-bimestral",
    component: () => import("../pages/gestor/relatorio/bimestral/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + "/relatorio/avaliacoes",
    name: "gestor-relatorio-acesso-avaliacoes",
    component: () => import("../pages/gestor/relatorio/avaliacao/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  {
    path: prefixoGestor + "/ver/turmas/:professor",
    name: "gestor-ver-turmas-professor",
    component: () => import("../pages/gestor/professorTurma/VerTurmas.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  {
    path: prefixoGestor + "/ver/turma/:turma/:escola/:professor",
    name: "gestor-ver-turma-professor",
    component: () => import("../pages/gestor/professorTurma/VerTurma.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  {
    path: prefixoGestor + "/marketing-educacional",
    name: "gestor-marketing-educacional",
    component: () =>
      import("../pages/gestor/pecasComunicacao/PecasComunicacaoInicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  {
    path: prefixoGestor + "/comunicados",
    name: "gestor-comunicados",
    component: () => import("../pages/gestor/comunicados/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + "/issuu",
    name: "LivrosEmbedIssuuGestor",
    ttl: "LivrosEmbedIssuuGestor",
    icon: "mdi-home-outline",
    meta: {
      id_privilegio: privilegioGestor,
    },
    component: () => import("../pages/gestor/LivrosEmbedIssuu.vue"),
    menu: false,
  },
  {
    path: prefixoGestor + "/avaliacoes-old",
    name: "AvaliacoesGestor",
    component: () => import("../pages/gestor/avaliacoes/Inicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  {
    path: prefixoGestor + "/avaliacao-old/criar/:id_avaliacao?",
    name: "AvaliacoesCriarGestor",
    component: () => import("../pages/gestor/avaliacoes/criar/CriarInicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  //Imprimir avaliacao
  {
    path: prefixoGestor + "/avaliacoes/:id_avaliacao/imprimir",
    name: "imprimir-avaliacoes-gestor",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/avaliacoes/imprimir/ImprimirInicio.vue"
      ),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },
  // avaliacao demo
  {
    path: prefixoGestor + "/avaliacoes",
    name: "AvaliacoesGestorDemo",
    component: () => import("../pages/gestor/avaliacoes/InicioNovo.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },
  //editar demo
  {
    path: prefixoGestor + "/avaliacao/criar/:id_avaliacao?",
    name: "editar-avaliacao-gestor",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/novaAvaliacao/criar/CriarInicio.vue"
      ),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },
  {
    path:
    prefixoGestor +
      "/avaliacoes/:id_avaliacao/aplicacao/:id_aplicacao/inserir-respostas",
    name: "inserir-respostas-aplicacao-gestor-nova",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/novaAvaliacao/inserirRespostasAplicacao/Home.vue"
      ),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },
  {
    path:
    prefixoGestor +
      "/avaliacoes/:id_avaliacao/aplicacao/:id_aplicacao/inserir-respostas/turma/:id_turma/aluno/:id_aluno",
    name: "inserir-respostas-aplicacao-aluno-gestor-nova",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/novaAvaliacao/inserirRespostasAplicacao/aluno/Home.vue"
      ),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },
  // Atividades
  {
    path: prefixoGestor + "/atividades",
    name: "AtividadesGestor",
    component: () => import("@/pages/gestor/atividades/Inicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },

  {
    path: prefixoGestor + "/atividade/criar/:id_atividade?",
    name: "criar-atividade-gestor",
    component: () =>
      import("@/pages/professor/seusArquivos/atividades/criar/CriarInicio.vue"),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },
  {
    path:
      prefixoGestor + "/avaliacoes/:id_avaliacao?",
    name: "seus-arquivos-atividades-avaliacoes-gestor",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/novaAvaliacao/aplicacao/Inicio.vue"
      ),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },
  //Imprimir nova modelagem avaliacao
  {
    path:
    prefixoGestor +
      "/avaliacoes/aplicacao/:id_avaliacao?/imprimir/:id_aplicacao",
    name: "imprimir-avaliacoes-gestor-aplicacao",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/novaAvaliacao/imprimir/ImprimirInicio.vue"
      ),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },
  {
    path: prefixoGestor + "/atividades/:id_atividade/imprimir",
    name: "imprimir-atividade-gestor",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/atividades/imprimir/ImprimirInicio.vue"
      ),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },

  {
    path: prefixoGestor + "/atividades/:id_atividade/acompanhamento",
    name: "acompanhamento-atividade-gestor",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/atividades/acompanhamento/Inicio.vue"
      ),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },
  {
    path: prefixoGestor + "/configuracao-acesso",
    name: "gestor-configuracao-acesso",
    component: () => import("../pages/gestor/cadastro/Home.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegioGestor,
    },
  },

  // Inserir respostas
  {
    path:
      prefixoGestor +
      "/avaliacoes/:id_avaliacao/aplicacao/:id_aplicacao/inserir-respostas",
    name: "inserir-respostas-aplicacao-gestor",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/novaAvaliacao/inserirRespostasAplicacao/Home.vue"
      ),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },
  {
    path:
      prefixoGestor +
      "/avaliacoes/:id_avaliacao/aplicacao/:id_aplicacao/inserir-respostas/turma/:id_turma/aluno/:id_aluno",
    name: "inserir-respostas-aplicacao-aluno-gestor",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/novaAvaliacao/inserirRespostasAplicacao/aluno/Home.vue"
      ),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },
  {
    path: prefixoGestor + "/avaliacao/criar/:id_avaliacao?",
    name: "criar-avaliacao-gestor",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/novaAvaliacao/criar/CriarInicio.vue"
      ),
    beforeEnter: auth,
    meta: { id_privilegio: privilegioGestor },
  },
	{
		path: prefixoGestor + "/faq",
		name: "faq-gestor",
		component: () => import("../pages/professor/faqProfessor/faqProfessor.vue"),
		beforeEnter: auth,
		meta: {
			id_privilegio: privilegioGestor,
		},
	},
];

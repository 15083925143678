import store from "../store";
export default async (to, from, next) => {
  let user = await store.dispatch("usuarioStore/loadUser");
  let idPrivilegio = to.meta.id_privilegio ? to.meta.id_privilegio : 0;

  let canAccessRoute =
    idPrivilegio && idPrivilegio.includes(user.id_privilegio);

  if (!user.id || !canAccessRoute) {
    window.location.href = "/";
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("usuario");

    return false;
  } else if (user['obra'] === 4) {
    return horaDeAprender(to, from, next);
  } else {
    next();
  }
};

async function horaDeAprender(to, from, next) {
  const user = await store.dispatch("usuarioStore/loadUser");
  const isProfessor = window.location.pathname.includes('/professor');
  const ehPropfessorInfantil = user['anos']?.every(item => item.nome.toLowerCase().includes('grupo')) || false;
  const path = window.location.pathname;

  const redirectionMap = {
    '/avaliacao/criar': true,
    '/seus-arquivos/avaliacoes': true,
    '/seus-arquivos/relatorios': true,
    '/gestor/avaliacoes': true,
    '/professor-gestor/albuns': true,
    '/professor-gestor/recursos-educacionais/planejamento': true,
    '/professor-gestor/recursos-educacionais/audios-ingles': true,
    '/professor-gestor/recursos-educacionais/disciplinas?tipo=sequencia': true,
    '/gestor/marketing-educacional': true,
    '/gestor/professores-turma': true,
    '/professor-gestor/minhas-turmas': true,
    ...(
      ehPropfessorInfantil ? 
      {
        '/professor/seus-arquivos': true,
        '/professor/atividade/criar': true
      } : {}
    ),

  };

  const shouldRedirect = Object.keys(redirectionMap).some(key => path.includes(key));
  
  if (shouldRedirect) {
    window.location.href = isProfessor ? '/professor/home' : '/gestor/home';
    return false;
  }

  const rotaParaTurma = /\/professor\/sala-aula\/escola\/[0-9]*\/turma\/[0-9]*/;
  if (rotaParaTurma.test(path)) {
    window.location.href = '/professor/home';
    return false;
  }

  return next();
}

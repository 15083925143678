<template>
  <v-navigation-drawer :mini-variant-width="70" app :permanent="!isMobile" :expand-on-hover="!isMobile"
    v-model="isMenuOpen" :style="{ background: 'var(--main-color)', height: '100%' }">
    <v-list class="px-2">
      <v-list-item v-for="(menu, i) in menus" :key="'menu-aluno-' + i" @click="onClickFunction(menu)"
        class="white--text d-flex align-center" :class="{
    'lista--ativa': rotaAtiva(menu.rota, menu.value),
    'mb-5': menu.titulo === 'Seus Arquivos',
  }">
        <v-list-item-icon class="d-flex align-center justify-center mx-0 my-auto">
          <!-- <component :is="menu.icon" color="#fff" /> -->
          <v-img contain max-width="30" max-height="30" :src="renderIcon(menu.icon)" />
          <div v-if="menu.titulo === 'Livros'" class="divisor mb-2" />
        </v-list-item-icon>

        <v-list-item-title class="ms-5" :style="{ whiteSpace: 'normal' }">
          {{ menu.titulo }}
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <!--      <v-list class="px-2" v-show="privilegio == 5">-->
    <!--        <v-list-item-->
    <!--            v-for="(menu, i) in menusConteudo"-->
    <!--            :key="'menu-conteudo-' + i"-->
    <!--            @click="clickAcaoMenu(menu)"-->
    <!--            class="white&#45;&#45;text"-->
    <!--            :class="{'lista&#45;&#45;ativa': rotaAtiva(menu.rota), 'mb-3': menu.titulo === 'Relatório das Questões'}"-->
    <!--        >-->
    <!--          <v-list-item-icon class="my-auto">-->
    <!--            &lt;!&ndash; <component :is="menu.icon" color="#fff" /> &ndash;&gt;-->
    <!--            <v-img max-width="30" max-height="30" :src="menu.icon"/>-->
    <!--            <div v-if="menu.titulo === 'Relatório das Questões'" class="divisor"></div>-->
    <!--          </v-list-item-icon>-->

    <!--          <v-list-item-title class="my-2" :style="{ whiteSpace: 'normal' }">-->
    <!--            {{ menu.titulo}}-->
    <!--          </v-list-item-title>-->
    <!--        </v-list-item>-->
    <!--      </v-list>-->

    <ModalSair v-if="modalSairPlataformaAberto" descricao="Você realmente deseja sair da plataforma?"
      :modal-aberto="modalSairPlataformaAberto" @close-modal="modalSairPlataformaAberto = false"
      @continuar="modalSairPlataformaAberto = false" @sair="
    modalSairPlataformaAberto = false;
  $router.replace('/login');
  " />
    <ModalPaginaConstrucao @close-modal="modalPaginaConstrucaoAberto = false"
      :modal-aberto="modalPaginaConstrucaoAberto" />
  </v-navigation-drawer>
</template>

<script>
import ModalSair from "@/pages/aluno/ModalSair.vue";
import ModalPaginaConstrucao from "../ModalPaginaConstrucao.vue";
import alerts from "@/alerts";

export default {
  name: "NavbarAluno",
  props: ["toggleMenu", "user"],
  mixins: [alerts],
  components: { ModalSair, ModalPaginaConstrucao },
  data() {
    return {
      isMenuOpen: false,
      modalSairPlataformaAberto: false,
      modalPaginaConstrucaoAberto: false,
      menus: [
        // {
        //   titulo: "Início",
        //   // icon: House,
        //   icon: "/assets/icones/aluno/menu-home.svg",
        //   rota: "/aluno/home",
        // },
        // {
        //   titulo: "Componentes Curriculares",
        //   // icon: House,
        //   icon: "/assets/icones/aluno/menu-unidade.svg",
        //   rota: "/aluno/disciplinas",
        //   value: "disciplinas",
        // },
        // {
        //   titulo: "Avaliações",
        //   // icon: House,
        //   icon: "/assets/icones/aluno/menu-avaliacao.svg",
        //   rota: "/aluno/avaliacoes",
        //   value: "avaliacoes",
        // },
        // {
        //   titulo: "Mural",
        //   // icon: House,
        //   icon: "/assets/icones/aluno/menu-mural.svg",
        //   rota: "/aluno/mural",
        // },
        // {
        //   titulo: "Agenda",
        //   // icon: House,
        //   icon: "/assets/icones/aluno/menu-agenda.svg",
        //   rota: "/aluno/agenda",
        // },
        // {
        //   titulo: "Mensagens",
        //   // icon: House,
        //   icon: "/assets/icones/aluno/menu-mensagens.svg",
        //   rota: "/aluno/mensagens",
        // },
        // {
        //   titulo: "Acompanhamento",
        //   // icon: House,
        //   icon: "/assets/icones/aluno/menu-acompanhamento.svg",
        //   rota: "/aluno/acompanhamento",
        // },
        //
        // {
        //   titulo: "Games",
        //   // icon: House,
        //   icon: "/assets/icones/aluno/menu-games.svg",
        //   rota: "#",
        // },

        {
          icon: require("@assets/icones/menu-comunicado.png"),
          titulo: "Comunicados",
          rota: "/aluno/comunicados",
          ativo: false,
        },
        {
          titulo: "Ajuda",
          icon: "/assets/icones/menu-faq.svg",
          rota: "/aluno/faq",
        },
        {
          titulo: "Sair",
          icon: "/assets/icones/aluno/menu-sair.svg",
          // icon: Logout,
          rota: "sair",
          onClickFunction: () => {
            this.sair();
          },
        },
      ],
    };
  },
  methods: {
    rotaAtiva(rota) {
      return this.$route.fullPath.indexOf(rota) !== -1;
    },
    renderIcon(icon) {
      return `${icon}`;
    },
    clickAcaoMenu(menu) {
      if (menu.rota == this.$router.currentRoute.path) {
        return;
      }
      if (menu.rota == "#") {
        this.modalPaginaConstrucaoAberto = true;
      } else if (menu.rota != this.$router.fullPath) {
        this.$router.push(menu.rota);
      }
    },
    onClickFunction(menu) {
      if (menu.onClickFunction) {
        menu.onClickFunction();
      } else {
        this.clickAcaoMenu(menu);
      }
    },
    sair() {
      this.modalSairPlataformaAberto = true;
    },
  },
  watch: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width <= 600;
    },
  },
  created() {
    this.isMenuOpen = this.menuOpen;
    this.menus = this.user.modulos.concat(this.menus);
  },
};
</script>

<style scoped lang="scss">
.v-responsive__content {
  width: unset !important;
}
</style>

import auth from "@/middleware/auth";

let prefixo = "/professor-gestor";
let prefixoProfessor = "/professor";
const privilegios = [2, 6];

export default [
  //Recursos Educacionais
  {
    path: prefixo + "/recursos-educacionais",
    name: "recursos-educacionais",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/RecursosEducacionaisInicio.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/disciplinas",
    name: "disciplinas",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/caminho/DisciplinasInicio.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },

  {
    path: prefixo + "/recursos-educacionais/video/disciplina/:id_disciplina",
    name: "material-videos",
    component: () =>
      import("../pages/professor/recursosEducacionais/videos/VideosInicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },

  {
    path: prefixo + "/recursos-educacionais/:tipo/disciplina/:id_disciplina",
    name: "material-recursos-educacionais",
    component: () =>
      import("../pages/professor/recursosEducacionais/caminho/AnosInicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/material/:tipo/assistir",
    name: "videoaulas-assuntos",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/videoaulasAnimacoes/AssistirVideoAulas.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path:
      prefixo +
      "/videoaulas/disciplina/:id_disciplina/ano/:id_ano/assistir-videoaula/:id_video?",
    name: "assistir-videoaula",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/videoaulasAnimacoes/AssistirVideoAulas.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/infograficos-mapas",
    name: "infograficos-mapas",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/infograficosMapas/InfograficosMapasInicio.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },

  {
    path: prefixo + "/recursos-educacionais/educacao-interativa",
    name: "educacao-interativa",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/educacaoInterativa/EducacaoInterativaInicio.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/educacao-interativa/sistema-solar",
    name: "sistema-solar",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/educacaoInterativa/cards/SistemaSolar.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/educacao-interativa/corpo-humano",
    name: "corpo-humano",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/educacaoInterativa/cards/CorpoHumano.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/educacao-interativa/evo-historia",
    name: "evo-historia",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/educacaoInterativa/cards/EvoHistoria.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/educacao-interativa/evo-atlas",
    name: "evo-atlas",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/educacaoInterativa/cards/EvoAtlas.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/educacao-interativa/evo-quimica",
    name: "evo-quimica",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/educacaoInterativa/cards/EvoQuimica.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/educacao-interativa/evo-games",
    name: "evo-games",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/educacaoInterativa/cards/EvoGames.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/educacao-interativa/evo-quiz",
    name: "evo-quiz",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/educacaoInterativa/cards/EvoQuiz.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },

  {
    path: prefixo + "/recursos-educacionais/podcast",
    name: "podcast",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/podcast/PodcastInicio.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/audios-ingles",
    name: "audios-ingles",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/audiosIngles/AudiosInglesInicio.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/audios-infantis",
    name: "audios-infantis",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/audiosInfantis/AudiosInfantisInicio.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/planejamento",
    name: "planejamento-professor",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/planejamento/PlanejamentoInicio.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/sequencia/:id_disciplina",
    name: "material-sequencia-didatica",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/sequenciaDidatica/SequenciaDidaticaInicio.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/projeto-integrador/:id_disciplina",
    name: "projeto-integrador",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/projetosIntegradores/ProjetosIntegradoresInicio.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },

  {
    path: prefixo + "/recursos-educacionais/conteudo-aberto",
    name: "conteudo-aberto",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/ConteudoAberto/ConteudoAbertoHome.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },
  {
    path: prefixo + "/recursos-educacionais/conteudo-aberto/:id_disciplina",
    name: "conteudo-aberto-disciplina",
    component: () =>
      import(
        "../pages/professor/recursosEducacionais/ConteudoAberto/ConteudoAbertoDisciplina.vue"
        ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },

  //Minhas Turmas
  {
    path: prefixo + "/minhas-turmas",
    name: "minhas-turmas",
    component: () =>
      import("../pages/professor/salaAula/MinhasTurmasInicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },

  // Livros Digitais
  {
    path: prefixo + "/livros-digitais",
    name: "prefixo-digitais",
    component: () => import("../pages/professor/livrosDigitais/Inicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },

  // Albuns de Fotos
  {
    path: prefixo + "/albuns",
    name: "albuns",
    component: () => import("../pages/professor/albuns/AlbunsInicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },

  // Assessoria pedagógica
  {
    path: prefixo + "/assessoria",
    name: "assessoria-professor",
    component: () =>
      import("../pages/professor/assessoriaPedagogica/AssessoriaInicio.vue"),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios,
    },
  },

  //Avaliação
  {
    path:
      prefixoProfessor +
      "/seus-arquivos/avaliacao/:id_avaliacao/acompanhamento",
    name: "acompanhamento-avaliacao",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/avaliacoes/acompanhamento/Inicio.vue"
        ),
    beforeEnter: auth,
    meta: {id_privilegio: privilegios},
  },

  //Acompanhamento de desempenho de avaliação com nova remediação
  {
    path:
      prefixoProfessor +
      "/seus-arquivos/avaliacao/:id_avaliacao/acompanhamento/nova-remediacao",
    name: "acompanhamento-avaliacao-nova-remediacao",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/avaliacoes/acompanhamento-novo/Inicio.vue"
        ),
    beforeEnter: auth,
    meta: {id_privilegio: privilegios},
  },


  //Acompanhamento de desempenho de avaliação/aplicação com nova remediação
  {
    path:
      prefixoProfessor +
      "/seus-arquivos/avaliacao/:id_avaliacao/aplicacao/:id_aplicacao/acompanhamento/nova-remediacao",
    name: "acompanhamento-aplicacao",
    component: () =>
      import(
        "@/pages/professor/seusArquivos/avaliacoes/acompanhamento-novo/aplicacao/Inicio.vue"
        ),
    beforeEnter: auth,
    meta: {id_privilegio: privilegios},
  },

  // ver avaliação do aluno após ela ser respondida pelo aluno
  {
    path: prefixo + '/atividade/:id_avaliacao/aplicacao/:id_aplicacao/acompanhamento/:id_aluno/visualizacao',
    name: "remediacao-ver-atividade",
    component: () => import(
      '@/pages/professor/seusArquivos/avaliacoes/acompanhamento-novo/aplicacao/visualizar/Inicio.vue'
    ),
    beforeEnter: auth,
    meta: {
      id_privilegio: privilegios
    }
  }
];

<template>
  <ModalPadrao
    imagem="/assets/images/aluno/bye.gif"
    :descricao="descricao"
    :modal-aberto="modalSairPlataformaAberto"
    @close-modal="$emit('close-modal')"
    :hide-fechar="true"
  >
    <template v-slot:buttons>
      <div class="d-flex justify-center">
        <ButtonModal @button-click="$emit('continuar')" class="me-3">
          {{ descricaoButton }}
        </ButtonModal>
        <ButtonModal @button-click="$emit('sair')"> Sair </ButtonModal>
      </div>
    </template>
  </ModalPadrao>
</template>

<script>
import ButtonModal from "./ButtonModal.vue";
import ModalPadrao from "./ModalPadrao.vue";
export default {
  props: {
    modalAberto: {
      type: Boolean,
    },
    descricao: {
      type: String,
      required: true,
    },
    descricaoButton: {
      type: String,
      default: "Continuar Estudando",
    },
  },
  components: {
    ButtonModal,
    ModalPadrao,
  },
  computed: {
    modalSairPlataformaAberto() {
      return this.modalAberto;
    },
  },
};
</script>

<style></style>

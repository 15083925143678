<template>
  <v-app>
    <component
      :is="compNavbar"
      v-show="hasNavbar"
      class="menu-aside"
      :toggle-menu="menuOpen"
      :user="usuario"
      @modalAppAgenda="modalAppAgenda = true"
      @modalSair="modalSairAberto = true"
    />

    <v-main
      :class="
        $vuetify.breakpoint.width <= 600 || !hasNavbar
          ? 'padding-left-none'
          : 'padding-left-70'
      "
    >
      <v-container
        id="containerPrincipal"
        :class="!hasNavbar ? 'padding-none' : ''"
        fluid
        class="container-custom"
      >
        <keep-alive>
          <router-view
            :usuario="usuario"
            :key="$route.fullPath"
            :click-menu="clickedMenu"
          />
        </keep-alive>
      </v-container>
      <!--      <Footer :professor-footer="true" />-->

      <ModalSucessoErroWarning
        v-if="modalSucessoErroWarning"
        :modal-aberto="modalSucessoErroWarning"
        @close-modal="closeModal"
        :dadosModal="dadosModalSucessoErro"
      />

      <ModalInfoAppAgendaDemo
        v-if="modalAppAgenda && Boolean(usuario.demo)"
        :modal-aberto="modalAppAgenda"
        @close-modal="modalAppAgenda = false"
      />

      <ModalInfoApp
        v-if="modalAppAgenda && !Boolean(usuario.demo)"
        :modal-aberto="modalAppAgenda"
        @close-modal="modalAppAgenda = false"
      />
    </v-main>

    <ModalSair
      :modal-aberto="modalSairAberto"
      @close-modal="modalSairAberto = false"
      @sair="sair()"
      @continuar="modalSairAberto = false"
    />

    <!-- loading agora resgatado do store -->
    <loading :dialog-principal="dialog" />
  </v-app>
</template>

<script>
import NavbarProfessor from "../navbar/NavbarProfessor.vue";
import NavbarAluno from "../navbar/NavbarAluno.vue";
import NavbarConteudo from "../navbar/NavbarConteudo.vue";
import NavbarAcesso from "../navbar/NavbarAcesso.vue";
import NavbarGestor from "../navbar/NavbarGestor.vue";
import ModalInfoApp from "@/components/ModalInfoApp";
import ModalInfoAppAgendaDemo from "@/components/ModalInfoAppAgendaDemo";

import alerts from "@/alerts";
// import Pusher from "pusher-js";
import echoListen from "@/mixins/echo-listen";
import api from "@/api";
import Loading from "../../components/Loading.vue";
import ModalSair from "@/components/ModalSair.vue";
import ModalSucessoErroWarning from "@/components/ModalSucessoErroWarning.vue";
import { mapState, mapActions } from 'vuex';
// import Footer from "../footer/Footer.vue";

export default {
  name: "app",
  mixins: [alerts, echoListen],
  components: {
    ModalSair,
    NavbarProfessor,
    NavbarAluno,
    NavbarConteudo,
    NavbarAcesso,
    ModalInfoApp,
    NavbarGestor,
    Loading,
    ModalInfoAppAgendaDemo,
    ModalSucessoErroWarning,
  },

  data() {
    return {
      compNavbar: null,
      modalAppAgenda: false,
      modalSairAberto: false,
      notifications: false,
      sound: true,
      widgets: false,
      menuOpen: false,

      privilegio: null,
    };
  },
  computed: {
    usuario() {
      return this.$store.getters["usuarioStore/user"];
    },
    //Loading agora no app, utilizando o store
    dialog() {
      return this.$store.getters["loadingStore/loading"];
    },

    hasNavbar() {
      let exceptions = [
        "/literarios",
        "/",
        "/login",
        "/login2",
        "/cadastro-professor",
        "/cadastro-aluno",
        "/recuperar-senha",
        "/pdf-viewer",
        "/demo",
      ];
      let route = this.$route.path;

      for (let exception of exceptions) {
        if (route == exception) {
          return false;
        }
      }

      return true;
    },
    ...mapState('alertStore', ['modalSucessoErroWarning', 'dadosModalSucessoErro'])
  },
  watch: {
    usuario(value) {
      this.echoListen(value);
      this.privilegio = value.id_privilegio;
      this.changeMenuByPrivilege(this.privilegio);
      this.setColorSystem();
    },
  },
  methods: {
    clickedMenu() {
      this.menuOpen = !this.menuOpen;
    },
    setColorSystem() {
      let mainColor = null;
      let gradient = null;
      let headerGradient = null;
      let secondaryGradient;
      let secondaryColor = null;
      if (this.usuario.id) {
        if (this.usuario.id_privilegio == 3) {
          mainColor = "#3B368D";
          secondaryColor = "#C72E61";
          gradient = "90deg, #3B368D 0%, #E22D59 100%";
          headerGradient = "90deg, #3B368D 200px, #E22D59 100%";
          secondaryGradient = "90.21deg, #C72E61 -18%, #423589 99.82%";

          this.$refs.body.style.setProperty(
            "--secondary-gradient",
            secondaryGradient
          );
          this.$refs.body.style.setProperty(
            "--secondary-color",
            secondaryColor
          );
        } else {
          mainColor = this.usuario.cor;
          gradient = this.usuario.gradient;
          headerGradient = this.usuario.gradient_header;
        }
      } else {
        mainColor = "#E63B50";
        gradient = "80.58deg, #E53952 8.78%, #EE4949 48.21%, #E17C1E 92.88%";
        headerGradient =
          "80.58deg, #E53952 8.78%, #EE4949 48.21%, #E17C1E 92.88%";
      }

      this.$refs.body.style.setProperty("--header-gradient", headerGradient);
      this.$refs.body.style.setProperty("--main-color", mainColor);
      this.$refs.html.style.setProperty("--plyr-color-main", mainColor);
      this.$refs.body.style.setProperty("--main-gradient", gradient);
    },

    changeMenuByPrivilege(privilege) {
      switch (privilege) {
        case 3:
          this.compNavbar = NavbarAluno;
          break;
        case 2:
          this.compNavbar = NavbarProfessor;
          break;
        case 5:
          this.compNavbar = NavbarConteudo;
          break;
        case 7:
          this.compNavbar = NavbarAcesso;
          break;
        case 6:
          this.compNavbar = NavbarGestor;
          break;
      }
    },

    sair() {
      this.modalSairAberto = false;
      api.post("/sair");
      this.$router.replace("/");
    },
    ...mapActions('alertStore', ['closeModal'])
  },
  provide() {
    return {
      clickMenuApp: this.clickedMenu,
      usuario: this.usuario,
    };
  },

  mounted() {
    this.$refs.body = document.querySelector("body");
    this.$refs.html = document.documentElement;
    window.onbeforeunload = () => {
      api.get("/historico_acesso_gestor/atualizar-saida");
    };

    this.setColorSystem();
    // this.getColorSystem();
    // this.menus = this.verificarMenus();

    // Remove da Url o mt para validação do usuário de degustação
    let urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("mt")) {
      urlParams.delete("mt");
      let newQuery = urlParams.toString();
      // Verifique se há outros parâmetros na query
      if (newQuery.length > 0) {
        newQuery = "?" + newQuery;
      }

      this.$router.push({ path: location.pathname + newQuery });
    }
  },
};
</script>
<style lang="scss">
body {
  --main-color: "";
  --main-gradient: "";
}

.cor-sistema {
  background: var(--main-color);
}

.menu-aside {
  padding-top: 170px;

  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;

  @media (max-width: 600px) {
    padding-top: 20px;
  }
}

.absolute {
  position: absolute;
}

.w-full {
  width: 100%;
}

.container-custom {
  height: 100%;
  background: #f7f9fd;
  display: flex;
  flex-direction: column;
  flex: 2;
}

.border-botom--table {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}

.border-left--red {
  border-left: 2px solid #e7a0a7;
}

.bg-table--red {
  background-color: #ffdfe2;
}

.border-left--blue {
  border-left: 2px solid #648bc5;
}

.bg-table--blue {
  background-color: #abccee;
}

.border-left--orange {
  border-left: 2px solid #e7c28b;
}

.bg-table--orange {
  background-color: #ffedd2;
}

.border-left--green {
  border-left: 2px solid #99da9b;
}

.bg-table--green {
  background-color: #def3df;
}

.divisor {
  height: 2px;
  width: calc(100% - 16px);
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ddd;
  outline: none;
  border: none;
}

.lista--ativa {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 12px;
}

.transparent::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-attachment: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.padding-left-70 {
  padding-left: 70px !important;
}

.padding-left-none {
  padding-left: 0px !important;
}

.padding-none {
  padding: 0 !important;
}
</style>
